<template>
  <v-card
    style="height: 100%"
    rounded="sm"
  >
    <v-card-title class="card__title">
      <h5 class="text">
        {{ $t('langkey.hot-news') }}
      </h5>
    </v-card-title>
    <v-card-text class="card__content">
      <agile
        ref="carousel"
        class="campaign"
        :options="sliderOptions"
      >
        <div
          v-for="(item, index) in mockCampaignImages"
          :key="index"
          class="slide"
        >
          <v-card
            tile
            class="card__item"
          >
            <img
              class="img"
              :src="item"
            >
          </v-card>
        </div>
        <template slot="prevButton">
          <icon-arrow-circle />
        </template>
        <template slot="nextButton">
          <icon-arrow-circle />
        </template>
      </agile>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import '@/styles/components/campaign.scss';
</style>

<script>
import IconArrowCircle from '@/components/icons/IconArrowCircle';

export default {
  components: {
    IconArrowCircle,
  },
  data () {
    return {
      mockCampaignImages: [
        require('@/assets/images/Banner_Linksunten.webp'),
        require('@/assets/images/Lokale_Belieferung_Banner_Startseite_unten.webp'),
        require('@/assets/images/Banner_point.jpg'),
        require('@/assets/images/Lkw_Banner_Startseite_unten.webp'),
        require('@/assets/images/Banner_Plattform_Felgen_innen.webp'),
        require('@/assets/images/Campaign1.png'),
        require('@/assets/images/Campaign2.png'),
      ],
      sliderOptions: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 10000,
      },
    };
  },
};
</script>