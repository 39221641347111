<template>
  <v-card rounded="sm">
    <v-card-title class="card__title">
      <h5 class="text">
        {{ $t('langkey.our-top-sellers') }}
      </h5>
    </v-card-title>
    <v-card-text class="card__content">
      <agile
        ref="carousel"
        :options="sliderOptions"
      >
        <div
          v-for="(item, index) in topSellerData"
          :key="index"
          class="slide"
        >
          <v-card
            tile
            class="card__item"
            @click="onClickTopSeller(item)"
          >
            <img
              class="img"
              :src="item.image"
            >
            <h3>{{ item.brand }}</h3>
            <h3>{{ item.description }} {{ item.tread }}</h3>
            <h3>EAN: {{ item.ean }}</h3>
          </v-card>
        </div>
        <template slot="prevButton">
          <icon-arrow-circle />
        </template>
        <template slot="nextButton">
          <icon-arrow-circle />
        </template>
      </agile>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import '@/styles/components/top-seller.scss';
</style>

<script>
import IconArrowCircle from '@/components/icons/IconArrowCircle';

export default {
  components: {
    IconArrowCircle,
  },
  data() {
    return {
      sliderOptions: {
        slidesToShow: 1,
        dots: false,
      },
      topSellerData: [
        { brand: 'Kleber', tread: 'Quadraxer', description: '205/55 R16 91H', ean: '3528705456917', image: 'https://cdn.tyresnparts.com/profile/3528705456917/180x200-cc-xxxxxx.png' },
        { brand: 'Hankook', tread: 'Ventus S1', description: '225/45 R17 94Y', ean: '8808563455471', image: 'https://cdn.tyresnparts.com/profile/8808563455471/180x200-cc-xxxxxx.png' },
        { brand: 'Michelin', tread: 'Pilot Sport 4', description: '225/50 R17 98W', ean: '3528701837727', image: 'https://cdn.tyresnparts.com/profile/3528701837727/180x200-cc-xxxxxx.png' },
        { brand: 'Falken', tread: 'EuroAllSeason Van11', description: '235/65 R16C 115/113 R', ean: '4250427418124', image: 'https://cdn.tyresnparts.com/profile/4250427418124/180x200-cc-xxxxxx.png' },
        { brand: 'Michelin', tread: 'Primacy4', description: '235/55 R18 100W', ean: '3528701844411', image: 'https://cdn.tyresnparts.com/profile/3528701844411/180x200-cc-xxxxxx.png' },
        { brand: 'Continental', tread: 'AllSeasonContact', description: '235/55 R17 99H', ean: '4019238035377', image: 'https://cdn.tyresnparts.com/profile/4019238035377/180x200-cc-xxxxxx.png' },
      ],
    };
  },
  methods: {
    async onClickTopSeller(item) {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: item.ean,
          filters: {
            sortField: 'bestPrice', // initial tyre table sort
            sortDirection: 'ASC',
          },
        });
        if (this.$route.name !== 'tyre-search') {
          this.$router.push(this.$i18nRoute({
            name: 'tyre-search',
            query: {
              search: item.ean,
            },
          }));
        }
      } catch (err) {
        if (err === 'noResult') {
          setTimeout(() => {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: 'Tyres result not found',
              message: 'Please try with different parameters',
            });
          }, 200);
        }
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
  },
};
</script>