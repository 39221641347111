<template>
  <div class="p-home">
    <v-container>
      <div class="dashboard__content">
        <tyre-search-sidebar />
        <order-overview class="order-overview" />
        <top-seller class="top-seller" />
        <order-chart class="order-chart" />
        <campaign class="campaign" />
        <news-room class="news-room" />
      </div>
    </v-container>

    <info-dialog ref="searchInfoRef" />
  </div>
</template>

<style lang="scss">
@import '@/styles/pages/home.scss';
</style>

<script>
import TyreSearchSidebar from '@/components/TyreSearchSidebar';
import OrderOverview from '@/components/OrderOverview';
import OrderChart from '@/components/OrderChart';
import Campaign from '@/components/Campaign';
// import DownloadArea from '@/components/DownloadArea';
import TopSeller from '@/components/TopSeller';
import NewsRoom from '@/components/NewsRoom';
import InfoDialog from '@/components/InfoDialog';
import { mapState } from 'vuex';

export default {
  components: {
    TyreSearchSidebar,
    OrderOverview,
    OrderChart,
    Campaign,
    TopSeller,
    NewsRoom,
    InfoDialog,
  },
  computed: {
    ...mapState('dialog', ['showInfoDialog']),
    showInfoDialogStorage() {
      return JSON.parse(sessionStorage.getItem('showInfoDialog'));
    },
  },
  mounted() {
    if (this.showInfoDialogStorage) {
      this.$refs.searchInfoRef.show();
      this.$store.dispatch('dialog/setUserLoginInformation', {
        status: false,
        noRequest: true,
      });
    }
  },
};
</script>