<template>
  <v-card
    class="tire-search-sidebar"
    rounded="sm"
  >
    <v-card-title class="card__title">
      <h5 class="text">
        {{ $t('langkey.quick-tyre-search') }}
      </h5>
      <v-btn
        icon
        plain
        class="info__icon"
        @click="$refs.searchInfoRef.show()"
      >
        <icon-info color="var(--theme-gray-4)" />
      </v-btn>
    </v-card-title>
    <v-card-text class="card__content">
      <form
        name="tyre-search-sidebar"
        @submit.prevent="onSubmitQuickSearch"
      >
        <v-text-field
          v-model="searchData.matchcode"
          :error-messages="[errorMessage.matchcode]"
          :class="[
            'matchcode__input',
            isQuickSearchFormError ? 'show-error' : null
          ]"
          :label="`${$t('langkey.matchcodes')}`"
          hide-details="auto"
          solo
          @input="onInputQuickSearch"
        />
        <v-text-field
          v-model="searchData.manufacturers"
          :error-messages="[errorMessage.manufacturers]"
          :class="[
            'matchcode__input',
            isQuickSearchFormError ? 'show-error' : null
          ]"
          :label="`${$t('langkey.manufacturer')}`"
          hide-details="auto"
          solo
          @input="onInputQuickSearch"
        />
        <div
          class="attribute-wrapper"
        >
          <base-checkbox
            v-model="searchData.seasons.S"
            check-box-button
          >
            <template #icon>
              <icon-summer />
            </template>
          </base-checkbox>
          <base-checkbox
            v-model="searchData.seasons.W"
            check-box-button
          >
            <template #icon>
              <icon-winter />
            </template>
          </base-checkbox>
          <base-checkbox
            v-model="searchData.seasons.A"
            check-box-button
          >
            <template #icon>
              <icon-all-season />
            </template>
          </base-checkbox>
        </div>
        <v-btn
          block
          color="primary"
          class="icon search__button"
          type="submit"
          @submit.prevent="onSubmitQuickSearch"
        >
          {{ $t('langkey.search') }} <icon-search color="var(--theme-white)" />
        </v-btn>
      </form>
      <info-dialog ref="searchInfoRef" />
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import '@/styles/components/tire-search__sidebar.scss';
</style>

<script>
import IconSummer from '@/components/icons/IconSummer';
import IconWinter from '@/components/icons/IconWinter';
import IconAllSeason from '@/components/icons/IconAllSeason';
import IconInfo from '@/components/icons/IconInfo';
import InfoDialog from '@/components/InfoDialog';

export default {
  components: {
    IconSummer,
    IconWinter,
    IconAllSeason,
    IconInfo,
    InfoDialog,
  },
  data() {
    return {
      searchData: {
        matchcode: '',
        manufacturers: '',
        seasons: {
          S: false,
          W: false,
          A: false,
        },
      },
      errorMessage: {
        matchcode: '',
        manufacturers: '',
      },
    };
  },
  computed: {
    isQuickSearchFormError() {
      return Object.values(this.errorMessage).some((val) => val !== '');
    },
  },
  methods: {
    validateQuickSearchForm() {
      if (this.searchData.matchcode === '' && this.searchData.manufacturers === '') {
        this.errorMessage = {
          ...this.errorMessage,
          manufacturers: this.$t('langkey.manufacturer-matchcode-required'),
        };
      } else {
        this.errorMessage = {
          matchcode: '',
          manufacturers: '',
        };
      }
    },
    async onSubmitQuickSearch() {
      this.validateQuickSearchForm();
      if (this.isQuickSearchFormError) {
        return;
      }

      const selectedSeasons = Object.keys(this.searchData.seasons)
        .filter((seasonKey) => this.searchData.seasons[seasonKey]);
      let tmpSeasons = selectedSeasons;
      const seasonFilterValue = this.searchData.matchcode.charAt(0).toUpperCase();
      if (this.searchData.matchcode.length > 0 &&
        ['S','W','A','G'].includes(seasonFilterValue)
      ) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'G') {
          adjustedSeasonFilterValue = 'A';
        }
        tmpSeasons = [
          ...tmpSeasons,
          adjustedSeasonFilterValue,
        ];
      }

      const uniqueSeasons = [...new Set(tmpSeasons)];
      let filterPayload = {
        seasons: uniqueSeasons.join(),
        manufacturers: this.searchData.manufacturers,
        sortField: 'bestPrice', // initial tyre table sort
        sortDirection: 'ASC',
      };

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        if (this.searchData.matchcode.length === 0) {
          await this.$store.dispatch('tyre/getTyres', {
            ...filterPayload,
            sortField: 'bestPrice', // initial tyre table sort
            sortDirection: 'ASC',
            isSearch: true,
          });
        } else {
          await this.$store.dispatch('tyre/searchTyreResult', {
            searchValue: this.searchData.manufacturers.length > 0
              ? `${this.searchData.matchcode} ${this.searchData.manufacturers}`
              : this.searchData.matchcode,
            filters: {
              ...filterPayload,
              manufacturers: '',
              sortField: 'bestPrice', // initial tyre table sort
              sortDirection: 'ASC',
            },
          });
        }
      } catch (err) {
        if (err === 'noResult') {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Tyres result not found',
            message: 'Please try with different parameters',
          });
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }

      let routerPayload = {};
      if (this.searchData.matchcode.length > 0) {
        if (this.searchData.manufacturers.length > 0) {
          routerPayload = {
            ...routerPayload,
            search: `${this.searchData.matchcode} ${this.searchData.manufacturers}`,
          };
        } else {
          routerPayload = {
            ...routerPayload,
            search: this.searchData.matchcode,
          };
        }
      }

      if (filterPayload.seasons.length > 0) {
        routerPayload = {
          ...routerPayload,
          seasons: filterPayload.seasons,
        };
      }

      if (this.searchData.matchcode.length === 0 && this.searchData.manufacturers.length > 0) {
        routerPayload = {
          ...routerPayload,
          manufacturers: this.searchData.manufacturers,
        };
      }

      this.$router.push(this.$i18nRoute({
        name: 'tyre-search',
        query: routerPayload,
      }));
    },
    onInputQuickSearch() {
      if (this.isQuickSearchFormError) {
        this.errorMessage = {
          matchcode: '',
          manufacturers: '',
        };
      }

    },
  },
};
</script>