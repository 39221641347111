<template>
  <v-card
    rounded="sm"
    class="order-chart"
  >
    <v-card-title class="card__title">
      <h5 class="text">
        {{ $t('langkey.breakdown') }}
      </h5>
    </v-card-title>
    <v-card-text class="card__content">
      <!-- <doughnut-chart
        :height="110"
        :width="110"
        :chart-data="chartData"
        :chart-options="chartOptions"
        @generated="setLegend"
      /> -->
      <!-- <div
        class="custom-legend"
        v-html="customLegend"
      /> -->
      <div class="table-header">
        <span>{{ $t('langkey.status') }}</span>
        <span class="orders">{{ $t('langkey.orders') }}</span>
      </div>
      <ul class="legend">
        <li
          v-for="(status, index) in Object.keys(orderStatus)"
          :key="`${index}`"
          class="legend__item"
        >
          <span>{{ $t(`langkey.order.status.${status}`) | capitalize }}</span>
          <span class="data">{{ orderStatus[status] }}</span>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import '@/styles/components/order-chart.scss';
</style>

<script>
import { mapState } from 'vuex';
// import DoughnutChart from '@/plugins/DoughnutChart.js';
export default {
  components: {
    // DoughnutChart,
  },
  data() {
    return {
      chartColors: {
        placed: '#21D59B',
        process: '#FDE32A',
        dispatched: '#0058FF',
        invoice: '#F99600',
        canceled: '#F0142F',
      },
      // chartData: null,
      // chartOptions: null,
      // customLegend: null,
    };
  },
  computed: {
    ...mapState({
      orderStatus: (state) => state.order.orderStatus,
    }),
  },
  created() {
    this.$store.dispatch('order/getOrderStatus');
    // this.fillData();
  },
  methods: {
    // fillData () {
    //   this.chartData = {
    //     labels: ['Order placed', 'In process', 'Dispatched', 'Invoice', 'Order canceled'],
    //     datasets: [
    //       {
    //         label: 'Data One',
    //         backgroundColor: [
    //           ...Object.values(this.chartColors),
    //         ],
    //         data: [68, 32, 41, 26, 7],
    //       },
    //     ],
    //   };
    //   this.chartOptions = {
    //     maintainAspectRatio:false,
    //     legend: {
    //       display: false,
    //     },
    //     legendCallback: function(chart) {
    //       let legendHTML = [];
    //       legendHTML.push('<ul>');
    //       let labels = chart.data.labels;
    //       let datasets = chart.data.datasets[0];
    //       for (let i in labels) {
    //         legendHTML.push('<li>');
    //         legendHTML.push('<div class="left">');
    //         legendHTML.push(`<div class="color" style="background-color: ${datasets.backgroundColor[i]}"></div>`);
    //         legendHTML.push(`<span>${labels[i]}</span>`);
    //         legendHTML.push('</div>');
    //         legendHTML.push(`<span class="right">${datasets.data[i]}</span>`);
    //         legendHTML.push('</div>');
    //       }
    //       legendHTML.push('</ul>');
    //       return legendHTML.join('');
    //     },
    //   };
    // },
    // setLegend(HTML) {
    //   this.customLegend = HTML;
    // },
  },
};
</script>