<template>
  <v-card rounded="sm">
    <v-card-title class="card__title">
      <h5 class="text">
        {{ $t('langkey.order-overview') }}
      </h5>
      <div class="right">
        <div
          v-click-outside="hideSearchBar"
          class="search-wrapper"
        >
          <v-btn
            v-if="!search"
            icon
            plain
            class="search__icon"
            @click="onSearch"
          >
            <icon-search size="18" />
          </v-btn>
          <v-btn
            v-else
            icon
            plain
            class="clear__icon"
            @click="clearSearch"
          >
            <icon-close color="var(--theme-gray-4)" />
          </v-btn>
          <input
            v-show="showSearchBar"
            ref="searchBar"
            v-model="search"
            type="search"
            class="search-primary"
            :placeholder="`${$t('langkey.search')}`"
          >
        </div>

        <v-btn
          icon
          plain
          @click="onFilter"
        >
          <icon-filter />
        </v-btn>
        <!-- <base-dropdown is-filter /> -->
      </div>
    </v-card-title>
    <v-data-table
      ref="tableOrderOverview"
      class="primary-style table__order-overview"
      :headers="tableFieldOrderOverview"
      :items="adjustedTableItems"
      :loading="loading"
      :no-data-text="$t('langkey.no-previous-order-available')"
      sort-by="id"
      sort-desc
      :custom-sort="customSort"
      hide-default-footer
      fixed-header
      height="336px"
      disable-pagination
    >
      <template
        v-for="(field, index) in tableFieldOrderOverview"
        v-slot:[`header.${field.value}`]="{ header }"
      >
        <div
          :key="`${field.value}${index}`"
          class="d-flex align-center justify-center"
        >
          <span>{{ $t(header.text) }}</span>
          <div class="icon__sort" />
        </div>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`orderOverview${index}`"
          >
            <td
              class="text-center"
              @click="onRowSelected(item, index)"
            >
              {{ item.created }}
            </td>
            <td
              class="text-center"
              @click="onRowSelected(item, index)"
            >
              {{ item.id }}
            </td>
            <td
              class="text-left"
              @click="onRowSelected(item, index)"
            >
              {{ item.comment }}
            </td>
            <td
              class="text-center"
            >
              <div
                v-if="!empty(item.invoicePdfUrls)"
                class="invoice-wrapper"
              >
                <download-invoice
                  v-for="(id, number) in item.invoicePdfUrls"
                  :id="id"
                  :key="number"
                  :number="number"
                />
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td
              class="text-center"
              @click="onRowSelected(item, index)"
            >
              <order-status
                :item="item"
                hide-text
              />
            </td>
            <td
              class="text-center"
            >
              <a
                v-if="item.parcels.length > 0"
                class="tracking"
                :href="item.parcels[item.parcels.length-1].shipmentTrackingUrl"
                target="_blank"
                rel="nofollow"
              >
                <img
                  class="courier-logo"
                  :src="item.parcels[item.parcels.length-1].courier.logo"
                >
              </a>
              <a
                v-else
              >-</a>
            </td>
          </tr>
          <tr v-if="isLoadingPagination">
            <td
              :colspan="tableFieldOrderOverview.length"
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="accent"
              />
            </td>
          </tr>
          <tr v-else-if="isNoMorePaginationResult">
            <td
              :colspan="tableFieldOrderOverview.length"
              class="text-center"
            >
              {{ $t('langkey.no-more-result') }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<style lang="scss">
@import '@/styles/components/order-overview.scss';
</style>

<script>
import IconFilter from '@/components/icons/IconFilter';
import DownloadInvoice from '@/components/DownloadInvoice';
import OrderStatus from '@/components/OrderStatus';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { tableFieldOrderOverview } from '@/configs/components/tableOrderOverview';
import { mapState } from 'vuex';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    DownloadInvoice,
    IconFilter,
    OrderStatus,
  },
  data () {
    return {
      search: '',
      showSearchBar: false,
      loading: false,
      tableScrollEl: null,
      pagination: 1,
      isLoadingPagination: false,
      isNoMorePaginationResult: false,
    };
  },
  computed: {
    ...mapState('order', ['orderItems']),
    tableFieldOrderOverview() {
      return tableFieldOrderOverview;
    },
    adjustedTableItems() {
      if (this.search) {
        return this.orderItems.filter((item) => {
          const foundArticle = item.articles.find((article) => {
            return article.description.toLowerCase().includes(this.search.toLowerCase()) || article.ipc === this.search;
          });
          return this.getDay(item.created).toLowerCase().includes(this.search) ||
            item.id === Number(this.search) ||
            item.invoiceNumber === this.search ||
            item.articles[0].comment.includes(this.search.toLowerCase()) ||
            item.trackinNumber === this.search ||
            item.supplier.name.toLowerCase().includes(this.search.toLowerCase()) ||
            foundArticle;
        }).slice().map((item) => {
          return {
            ...item,
            created: this.getDay(item.created),
            comment: item.articles.length ? item.articles[0].description : '-',
          };
        });
      } else {
        return this.orderItems.slice()
          .map((item) => {
            return {
              ...item,
              created: this.getDay(item.created),
              comment: item.articles.length ? item.articles[0].description : '-',
            };
          });
      }
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch('order/getOrder', {
      maxResult: 10,
      page: 1,
      sortField: 'id',
      sortDirection: 'DESC',
    })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {
    this.tableScrollEl = this.$refs.tableOrderOverview.$el.children[0];
    this.tableScrollEl.addEventListener('scroll', this.handleTableScroll);
  },
  beforeDestroy() {
    this.tableScrollEl.removeEventListener('scroll', this.handleTableScroll);
  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
        this.$nextTick(() => {
          this.$refs.searchBar.focus();
        });
      }
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
    },
    onFilter() {

    },
    getDay(date) {
      return dayjs(date).tz('Europe/Berlin').format('DD.MM.YY');
    },
    empty: _.isEmpty,
    onRowSelected(selectedRow, index) {
      this.$router.push(this.$i18nRoute({
        name: 'admin-orders',
        query: {
          orderID: selectedRow.id.toString(),
          orderIndex: index.toString(),
        },
      }));
    },
    customSort(items, sortBy, isDesc) {
      const prop = sortBy[0];
      if (sortBy.length === 0 && isDesc.length === 0) { //no sorting, default list
        return items;
      } else if (prop === 'created') {
        items.sort((a, b) => {
          const currentDate = a[prop].split('.').map((item) => Number(item));
          const nextDate = b[prop].split('.').map((item) => Number(item));
          const currentValue = a[prop] && currentDate.length > 0 ? new Date(currentDate[2], currentDate[1]-1, currentDate[0]) : null;
          const nextValue = b[prop] && nextDate.length > 0 ? new Date(nextDate[2], nextDate[1]-1, nextDate[0]) : null;
          if (isDesc[0]) {
            if (currentValue.getTime() < nextValue.getTime()) {
              return 1;
            }
            if (currentValue.getTime() > nextValue.getTime()) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue.getTime() > nextValue.getTime()) {
              return 1;
            }
            if (currentValue.getTime() < nextValue.getTime()) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else {
        items.sort((a, b) => {
          const currentValue = a[prop] ? a[prop] : '';
          const nextValue = b[prop] ? b[prop] : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      }
    },
    handleTableScroll() {
      const offsetHeight = this.tableScrollEl.offsetHeight;
      const scrollHeight = this.tableScrollEl.scrollHeight;
      const scrollYPos = this.tableScrollEl.scrollTop;

      if ((scrollHeight - (scrollYPos + offsetHeight)) <= 0.5
        && this.isLoadingPagination === false
        && this.isNoMorePaginationResult === false) {
        this.pagination += 1;
        this.getOrderNextPage();
      }
    },
    async getOrderNextPage() {
      this.isLoadingPagination = true;
      try {
        await this.$store.dispatch('order/getOrderNextPage', {
          maxResult: 10,
          page: this.pagination,
          sortField: 'id',
          sortDirection: 'DESC',
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
  },
};
</script>
